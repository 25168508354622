<template>
  <a-modal
    :title="title"
    :maskClosable=false
    :width="800"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    cancelText="关闭">

    <a-spin :spinning="confirmLoading">
      <a-form :form="form">

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="名称">
          <a-input placeholder="请输入名称" v-decorator="['name', validatorRules.name ]"/>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="快递100编码">
          <a-input placeholder="请输入快递编100编码" v-decorator="['code', validatorRules.code ]"/>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="我打编码">
          <a-input placeholder="请输入我打编码" v-decorator="['wdCode', validatorRules.wdCode ]"/>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="是为合作快递">
          <a-switch checkedChildren="是" unCheckedChildren="否" v-model="model.cooperation" />
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="logo">
          <a-upload
            v-decorator="['logo', validatorRules.logo]"
            listType="picture-card"
            class="avatar-uploader"
            :showUploadList="false"
            :action="uploadAction"
            :headers="headers"
            :beforeUpload="beforeUpload"
            @change="handleChange"
          >
            <img v-if="model.logo" :src="model.logo" alt="logo"
                 style="height:104px;max-width:300px"/>
            <div v-else>
              <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>
              <div class="ant-upload-text">上传</div>
            </div>
          </a-upload>
        </a-form-item>

      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
    // import {httpAction} from '@/api/manage'
    import pick from 'lodash.pick'
    import moment from "moment"
    import Vue from 'vue'
    import {ACCESS_TOKEN} from "@/store/mutation-types"

    export default {
        name: "ExpressModal",
        data() {
            return {
                title: "操作",
                visible: false,
                model: {},
                labelCol: {
                    xs: {span: 24},
                    sm: {span: 5},
                },
                wrapperCol: {
                    xs: {span: 24},
                    sm: {span: 16},
                },
                headers: {},
                uploadLoading: false,
                confirmLoading: false,
                form: this.$form.createForm(this),
                validatorRules: {
                    name: {rules: [{required: true, message: '请输入名称!'}]},
                    code: {rules: [{required: true, message: '请输入快递100编码!'}]}
                },
                url: {
                    add: "/express/express/add",
                    edit: "/express/express/edit",
                    fileUpload: window._CONFIG['domianURL'] + "/sys/common/upload",
                    imgerver: window._CONFIG['fileURL']
                },
            }
        },
        created() {
            const token = Vue.ls.get(ACCESS_TOKEN);
            this.headers = {"TOKEN": token};
        },
        computed: {
            uploadAction: function () {
                return this.url.fileUpload;
            }
        },
        methods: {
            add() {
                this.edit({});
            },
            edit(record) {
                this.form.resetFields();
                this.model = Object.assign({}, record);
                this.visible = true;
                this.$nextTick(() => {
                    this.form.setFieldsValue(pick(this.model, 'name', 'code', 'wdCode', 'logo','cooperation'))
                    //时间格式化
                });

            },
            close() {
                this.$emit('close');
                this.visible = false;
            },
            handleOk() {
                const that = this;
                var logo = this.model.logo;
                // 触发表单验证
                this.form.validateFields((err, values) => {
                    if (!err) {
                        that.confirmLoading = true;
                        let httpurl = '';
                        let method = '';
                        if (!this.model.id) {
                            httpurl += this.url.add;
                            method = 'post';
                        } else {
                            httpurl += this.url.edit;
                            method = 'put';
                        }
                        let formData = Object.assign(this.model, values);
                        //时间格式化

                        formData.logo = logo;
                        this.$message.error('没有执行此方法')
                        // httpAction(httpurl, formData, method).then((res) => {
                        //     if (res.success) {
                        //         that.$message.success(res.message);
                        //         that.$emit('ok');
                        //     } else {
                        //         that.$message.warning(res.message);
                        //     }
                        // }).finally(() => {
                        //     that.confirmLoading = false;
                        //     that.close();
                        // })


                    }
                })
            },
            handleCancel() {
                this.close()
            },
            beforeUpload: function (file) {
                var fileType = file.type;
                if (fileType.indexOf('image') < 0) {
                    this.$message.warning('请上传图片');
                    return false;
                }
                //TODO 验证文件大小
            },
            handleChange(info) { //上传图片回调
                if (info.file.status === 'uploading') {
                    this.uploadLoading = true
                    return
                }
                if (info.file.status === 'done') {
                    let response = info.file.response;
                    this.uploadLoading = false;
                    if (response.success) {
                        this.model.logo = response.message;
                    } else {
                        this.$message.warning(response.message);
                    }
                }
            }


        }
    }
</script>

<style lang="less" scoped>

</style>