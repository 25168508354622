<template>
  <a-card :bordered="false">

    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="24">

          <a-col :md="6" :sm="8">
            <a-form-item label="名称">
              <a-input placeholder="请输入名称" v-model="queryParam.name"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item label="快递100编码">
              <a-input placeholder="请输入快递100编码" v-model="queryParam.code"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">查询</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">重置</a-button>
            </span>
          </a-col>

        </a-row>
      </a-form>
    </div>

    <!-- 操作按钮区域 -->
    <div class="table-operator">
      <a-button @click="handleAdd" type="primary" icon="plus">新增</a-button>
      <a-dropdown v-if="selectedRowKeys.length > 0">
        <a-menu slot="overlay">
          <a-menu-item key="1" @click="batchDel">
            <a-icon type="delete"/>
            删除
          </a-menu-item>
        </a-menu>
        <a-button style="margin-left: 8px"> 批量操作
          <a-icon type="down"/>
        </a-button>
      </a-dropdown>
    </div>

    <!-- table区域-begin -->
    <div>
      <div class="ant-alert ant-alert-info" style="margin-bottom: 16px;">
        <i class="anticon anticon-info-circle ant-alert-icon"></i> 已选择 <a style="font-weight: 600">{{
        selectedRowKeys.length }}</a>项
        <a style="margin-left: 24px" @click="onClearSelected">清空</a>
      </div>

      <a-table
        ref="table"
        size="middle"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
        @change="handleTableChange">

        <span slot="action" slot-scope="text, record">
          <a @click="handleEdit(record)">编辑</a>

          <a-divider type="vertical"/>

          <a-popconfirm title="确定删除吗?" @confirm="() => handleDelete(record.id)">
            <a>删除</a>
          </a-popconfirm>
        </span>

        <span slot="logo" slot-scope="text, record">
          <img v-if="record.logo" style="width: 48px;height: 48px;" preview="logo" :src="record.logo">
          <span v-if="!record.logo">-</span>
        </span>
      </a-table>
    </div>
    <!-- table区域-end -->

    <!-- 表单区域 -->
    <express-modal ref="modalForm" @ok="modalFormOk"></express-modal>
  </a-card>
</template>

<script>
    import ExpressModal from './modules/ExpressModal'
    import {JeecgListMixin} from '@/mixins/JeecgListMixin'

    export default {
        name: "ExpressList",
        mixins: [JeecgListMixin],
        components: {
            ExpressModal
        },
        data() {
            return {
                description: '物流表管理页面',
                // 表头
                columns: [
                    {
                        title: '名称',
                        align: "center",
                        dataIndex: 'name'
                    },
                    {
                        title: '快递100编码',
                        align: "center",
                        dataIndex: 'code'
                    },
                    {
                        title: '我打编码',
                        align: "center",
                        dataIndex: 'wdCode'
                    },
                    {
                        title: 'logo',
                        align: "center",
                        dataIndex: 'logo',
                        scopedSlots: {customRender: 'logo'}
                    },
                    {
                        title: '是否为合作快递',
                        align: "center",
                        dataIndex: 'cooperation',
                        customRender: function (state) {
                            if (state == 0) {
                                return "否"
                            }
                            return "是"
                        }
                    },
                    {
                        title: '操作',
                        dataIndex: 'action',
                        align: "center",
                        scopedSlots: {customRender: 'action'},
                    }
                ],
                url: {
                    list: "/express/express/list",
                    delete: "/express/express/delete",
                    deleteBatch: "/express/express/deleteBatch",
                    fileUpload: window._CONFIG['domianURL'] + "/sys/common/upload",
                    imgerver: window._CONFIG['fileURL']
                },
            }
        },
        computed: {
            importExcelUrl: function () {
                return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`;
            }
        },
        methods: {}
    }
</script>
<style scoped>
  @import '../../assets/less/common.less';
</style>