/**
 * 新增修改完成调用 modalFormOk方法 编辑弹框组件ref定义为modalForm
 * 高级查询按钮调用 superQuery方法  高级查询组件ref定义为superQueryModal
 * data中url定义 list为查询列表  delete为删除单条记录  deleteBatch为批量删除
 */
// import {filterObj} from '@/utils/util';
// import { getAction, downFile } from '@/api/manage'
import Vue from 'vue'
import {ACCESS_TOKEN} from "@/store/mutation-types"

export const JeecgListMixin = {
  data() {
    return {
      //token header
      tokenHeader: {'TOKEN': Vue.ls.get(ACCESS_TOKEN)},
      /* 查询条件-请不要在queryParam中声明非字符串值的属性 */
      queryParam: {},
      /* 数据源 */
      dataSource: [],
      /* 分页参数 */
      ipagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30'],
        showTotal: (total, range) => {
          return range[0] + "-" + range[1] + " 共" + total + "条"
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0
      },
      /* 排序参数 */
      isorter: {
        column: 'createTime',
        order: 'desc',
      },
      /* 筛选参数 */
      filters: {},
      /* table加载状态 */
      loading: false,
      /* table选中keys*/
      selectedRowKeys: [],
      /* table选中records*/
      selectionRows: [],
      /* 查询折叠 */
      toggleSearchStatus: false,
      /* 高级查询条件生效状态 */
      superQueryFlag: false,
      /* 高级查询条件 */
      superQueryParams: ""
    }
  },
  created() {
    if (!this.disableMixinCreated) {
      console.log(' -- mixin created -- ')
      this.loadData();
      //初始化字典配置 在自己页面定义
      this.initDictConfig();
    }
  },
  methods: {
    loadData(arg) {
      if (!this.url.list) {
        this.$message.error("请设置url.list属性!")
        return
      }
      //加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.ipagination.current = 1;
      }
      var params = this.getQueryParams();//查询条件
      //cancelQuery 如果有值并且为1的时候取消查询
      if (params.cancelQuery === 1) {
        return;
      }
      var systemId = Vue.ls.get("systemId");
      if (!params['systemId'] && systemId) {
        params['systemId'] = systemId;
      }

      var systemType = Vue.ls.get("systemType");
      if (!params['systemType'] && systemType) {
        params['systemType'] = systemType;
        //为店铺时自动添加shopId 和  systemId相同
        if (systemType == 2) {
          if (!params['shopId'] && systemId) {
            params['shopId'] = systemId;
          }
        }
      }
      this.loading = true;
      this.$message.error('没有执行此方法')
      // getAction(this.url.list, params).then((res) => {
      //   if (res.success) {
      //     this.dataSource = res.result.records;
      //     this.ipagination.total = res.result.total;
      //   }
      //   if (res.code === 510) {
      //     this.$message.warning(res.message)
      //   }
      //   this.loading = false;
      // })
    },
    initDictConfig() {
      console.log("--这是一个假的方法!")
    },
    handleSuperQuery(arg) {
      //高级查询方法
      if (!arg) {
        this.superQueryParams = ''
        this.superQueryFlag = false
      } else {
        this.superQueryFlag = true
        this.superQueryParams = JSON.stringify(arg)
      }
      this.loadData()
    },
    getQueryParams() {
      debugger
      //获取查询条件
      let sqp = {}
      if (this.superQueryParams) {
        sqp['superQueryParams'] = encodeURI(this.superQueryParams)
      }
      var param = Object.assign(sqp, this.queryParam, this.isorter, this.filters);
      param.field = this.getQueryField();
      param.pageNo = this.ipagination.current;
      param.pageSize = this.ipagination.pageSize;
      if (localStorage.getItem("goodsId")) {
        param.goodsId = localStorage.getItem("goodsId");
        localStorage.removeItem("goodsId");
      }

      this.$message.error('没有执行此方法')
      // return filterObj(param);
      return null
    },
    getQueryField() {
      //TODO 字段权限控制
      var str = "id,";
      this.columns.forEach(function (value) {
        str += "," + value.dataIndex;
      });
      return str;
    },

    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectionRows = selectionRows;
    },
    onClearSelected() {
      this.selectedRowKeys = [];
      this.selectionRows = [];
    },
    searchQuery() {
      this.loadData(1);
    },
    superQuery() {
      this.$refs.superQueryModal.show();
    },
    searchReset() {
      this.queryParam = {}
      this.loadData(1);
    },
    batchDel: function () {
      if (!this.url.deleteBatch) {
        this.$message.error("请设置url.deleteBatch属性!")
        return
      }
      if (this.selectedRowKeys.length <= 0) {
        this.$message.warning('请选择一条记录！');
        return;
      } else {
        var ids = "";
        for (var a = 0; a < this.selectedRowKeys.length; a++) {
          ids += this.selectedRowKeys[a] + ",";
        }
        var that = this;
        this.$confirm({
          title: "确认删除",
          content: "是否删除选中数据?",
          onOk: function () {
            that.loading = true;
            that.$message.error('没有此方法')
            // deleteAction(that.url.deleteBatch, {ids: ids}).then((res) => {
            //   if (res.success) {
            //     that.$message.success(res.message);
            //     that.loadData();
            //     that.onClearSelected();
            //   } else {
            //     that.$message.warning(res.message);
            //   }
            // }).finally(() => {
            //   that.loading = false;
            // });
          }
        });
      }
    },
    handleDelete: function (id) {
      if (!this.url.delete) {
        this.$message.error("请设置url.delete属性!")
        return
      }
      this.$message.error('没有此方法')
      // var that = this;
      // deleteAction(that.url.delete, {id: id}).then((res) => {
      //   if (res.success) {
      //     that.$message.success(res.message);
      //     that.loadData();
      //   } else {
      //     that.$message.warning(res.message);
      //   }
      // });
    },
    handleEdit: function (record) {
      this.$refs.modalForm.edit(record);
      this.$refs.modalForm.title = "编辑";
      this.$refs.modalForm.disableSubmit = false;
    },

    edit: function (record) {
      this.$refs.modalForm1.edit(record);
      this.$refs.modalForm1.title = "审核";
      this.$refs.modalForm1.disableSubmit = false;
    },
    bannedEdit: function (record) {
      this.$refs.modalForm2.edit(record);
      this.$refs.modalForm2.title = "封禁";
      this.$refs.modalForm2.disableSubmit = false;
    },
    handleAdd: function () {
      this.$refs.modalForm.add();
      this.$refs.modalForm.title = "新增";
      this.$refs.modalForm.disableSubmit = false;
    },
    handleTableChange(pagination, filters, sorter) {
      //分页、排序、筛选变化时触发
      //TODO 筛选
      if (Object.keys(sorter).length > 0) {
        this.isorter.column = sorter.field;
        this.isorter.order = "ascend" == sorter.order ? "asc" : "desc"
      }
      this.ipagination = pagination;
      this.loadData();
    },
    handleToggleSearch() {
      this.toggleSearchStatus = !this.toggleSearchStatus;
    },
    modalFormOk() {
      // 新增/修改 成功时，重载列表
      this.loadData();
    },
    modalFormOk1() {
      // 新增/修改 成功时，重载列表
      this.loadData();
    },
    modalFormOk2() {
      // 新增/修改 成功时，重载列表
      this.loadData();
    },
    handleDetail: function (record) {
      this.$refs.modalForm.edit(record);
      this.$refs.modalForm.title = "详情";
      this.$refs.modalForm.disableSubmit = true;
    },
    /* 导出 */
    handleExportXls2() {
      let paramsStr = encodeURI(JSON.stringify(this.getQueryParams()));
      let url = `${window._CONFIG['domianURL']}/${this.url.exportXlsUrl}?paramsStr=${paramsStr}`;
      window.location.href = url;
    },
    handleExportXls(fileName) {
      if (!fileName || typeof fileName != "string") {
        fileName = "导出文件"
      }
      let param = {...this.queryParam};
      if (this.selectedRowKeys && this.selectedRowKeys.length > 0) {
        param['selections'] = this.selectedRowKeys.join(",")
      }
      console.log("导出参数", param)
      this.$message.error('没有此方法')
      // downFile(this.url.exportXlsUrl, param).then((data) => {
      //   if (!data) {
      //     this.$message.warning("文件下载失败")
      //     return
      //   }
      //   if (typeof window.navigator.msSaveBlob !== 'undefined') {
      //     window.navigator.msSaveBlob(new Blob([data], {type: 'application/vnd.ms-excel'}), fileName + '.xls')
      //   } else {
      //     let url = window.URL.createObjectURL(new Blob([data], {type: 'application/vnd.ms-excel'}))
      //     let link = document.createElement('a')
      //     link.style.display = 'none'
      //     link.href = url
      //     link.setAttribute('download', fileName + '.xls')
      //     document.body.appendChild(link)
      //     link.click()
      //     document.body.removeChild(link); //下载完成移除元素
      //     window.URL.revokeObjectURL(url); //释放掉blob对象
      //   }
      // })
    }
  }
}

